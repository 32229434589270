<template>
  <v-layout class="column sheet-form">
    <div class="form-main-header">
      <h1>Change Password</h1>
      <v-divider />
    </div>
    <slot name="top" />
    <v-form class="password-form" ref="changePasswordForm">
        <div>
            <label>Old Password</label>
            <CRInput
              v-model="changePasswordFormData.password"
              :rules="[
                (v) => isNotEmpty(v) || 'This field is required'
              ]"
              flat
              solo
              type="password"
              name="Old Password"
            />
            <label>New Password</label>
            <CRInput
              v-model="changePasswordFormData.newPassword"
              :rules="[
                (v) => isNotEmpty(v) || 'This field is required',
                (v) => !shouldValidatePassword || asyncPasswordResponse.data.valid || asyncPasswordResponse.data.message
              ]"
              flat
              solo
              type="password"
              name="New Password"
            />
            <label>Confirm Password</label>
            <CRInput
              v-model="changePasswordFormData.confirmPassword"
              :rules="[
                (v) => isNotEmpty(v) || 'This field is required',
                (v) => passwordsMatch(v) || 'Passwords must match'
              ]"
              flat
              solo
              type="password"
              name="Confirm Password"
            />
        </div>
      <div class="layout justify-center">
        <v-btn
          id="change-password-form-button-change-password"
          :disabled="loading"
          :loading="loading"
          class="btn-primaryaction"
          @click="submit"
        >
          Change Password
        </v-btn>
      </div>
    </v-form>
  </v-layout>
</template>

<script>
import { isNotEmpty } from '@/utils/validators'
import { authComputed } from '@/state/helpers'
import authService from '@/services/auth'

export const AUTH0_PASSWORD_ERROR_MESSAGE =
  'User does not have an Auth0 ID for username/password sign-in.'

export default {
  data() {
    return {
      changePasswordFormData: {
        newPassword: '',
        password: '',
        confirmPassword: '',
      },
      loading: false,
      errorMessage: '',
      disabled: false,
      displayErr: false,
      asyncPasswordResponse: {
        data: {
          valid: false,
          message: 'Something went wrong'
        }
      },
      shouldValidatePassword: false
    }
  },
  computed: {
    ...authComputed,
  },

  methods: {
    isNotEmpty,
    displayErrMsg(e, status) {
      this.displayErr = status
      this.errorMessage = e
    },
    async submit() {
      this.loading = true

      this.asyncPasswordResponse = await authService.validatePassword(this.changePasswordFormData.newPassword)
      this.shouldValidatePassword = true

      if (!this.$refs.changePasswordForm.validate()) {
        this.loading = false
        return
      }

      await this.$store
        .dispatch('auth/changePassword', {
          payload: {
            userId: this.currentUser.userId,
            newPassword: this.changePasswordFormData.newPassword,
            currentPassword: this.changePasswordFormData.password,
          },
        })
        .then(() => {
          this.$store.dispatch('app/showAlert', {
            message: 'Successfuly set password.',
          })
        })
        .catch((e) => {
          if (e.response.data.message === AUTH0_PASSWORD_ERROR_MESSAGE
          ) {
            this.$store.dispatch('app/showAlert', {
              message:
                'This user has not authenticated with Auth0, and therefore cannot update their password. Please logout and log back in.',
              type: 'error',
            })
          } else {
            this.$store.dispatch('app/showAlert', {
              message:
                'Something went wrong',
              type: 'error',
            })
          }
          this.loading = false
        })
    },

    passwordsMatch(password) {
    return password === this.changePasswordFormData.newPassword
    }
  },
}
</script>

<style lang="scss" scoped>
.password-form {
  width: 400px;
  align-self: center;
}
</style>
