var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"column sheet-form"},[_c('div',{staticClass:"form-main-header"},[_c('h1',[_vm._v("Change Password")]),_c('v-divider')],1),_vm._t("top"),_c('v-form',{ref:"changePasswordForm",staticClass:"password-form"},[_c('div',[_c('label',[_vm._v("Old Password")]),_c('CRInput',{attrs:{"rules":[
              (v) => _vm.isNotEmpty(v) || 'This field is required'
            ],"flat":"","solo":"","type":"password","name":"Old Password"},model:{value:(_vm.changePasswordFormData.password),callback:function ($$v) {_vm.$set(_vm.changePasswordFormData, "password", $$v)},expression:"changePasswordFormData.password"}}),_c('label',[_vm._v("New Password")]),_c('CRInput',{attrs:{"rules":[
              (v) => _vm.isNotEmpty(v) || 'This field is required',
              (v) => !_vm.shouldValidatePassword || _vm.asyncPasswordResponse.data.valid || _vm.asyncPasswordResponse.data.message
            ],"flat":"","solo":"","type":"password","name":"New Password"},model:{value:(_vm.changePasswordFormData.newPassword),callback:function ($$v) {_vm.$set(_vm.changePasswordFormData, "newPassword", $$v)},expression:"changePasswordFormData.newPassword"}}),_c('label',[_vm._v("Confirm Password")]),_c('CRInput',{attrs:{"rules":[
              (v) => _vm.isNotEmpty(v) || 'This field is required',
              (v) => _vm.passwordsMatch(v) || 'Passwords must match'
            ],"flat":"","solo":"","type":"password","name":"Confirm Password"},model:{value:(_vm.changePasswordFormData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.changePasswordFormData, "confirmPassword", $$v)},expression:"changePasswordFormData.confirmPassword"}})],1),_c('div',{staticClass:"layout justify-center"},[_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":"change-password-form-button-change-password","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Change Password ")])],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }